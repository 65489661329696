@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,400;0,500;1,400&family=roboto:ital,wght@0,300;0,400;1,400;1,500&display=swap');

html,
body {
    border: none;
    padding: 0;
    margin: 0;
    font-family: 'roboto', sans-serif;
}

a {
    text-decoration: none;
    color: unset
}

.main {}

.cursor-pointer {
    cursor: pointer;
}


.izimonie_form {
    max-width: 400px;
    margin: auto
}

.izimonie_form .fieldbox {
    width: 100%;
    margin-top: 20px;
}

.izimonie_form label .label_icon.important {
    height: 10px;
    width: 10px;
    background: url('icons/asterisks.svg');
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
}

.react-tel-input .form-control {
    width: calc(100% - 50px) !important;
    margin-left: 50px !important;
}

.izimonie_form label .label_icon.check {
    height: 20px;
    width: 20px;
    background: url('icons/check.svg');
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
}

.izimonie_form .fieldbox label {
    margin-bottom: 5px;
    font-family: 'roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height, or 175% */
    text-transform: capitalize;
    color: var(--dark4);
    font-family: 'roboto', sans-serif;
    cursor: pointer;
    display: flex;
}

.izimonie_form .fieldbox input {
    width: 100%;
    height: 50px;
    padding: 10px;
    border-radius: 5px;
    border: none;
    border-bottom: 1px solid var(--dark4);
    background: #dfdfdf;
    font-family: 'roboto', sans-serif;

}

.izimonie_form .fieldbox textarea {
    width: 100%;
    height: 70px;
    border-radius: 5px;
    border: none;
    border-bottom: 1px solid var(--dark4);
    padding: 10px;
    font-size: 14px;
    background: #dfdfdf;
    font-family: 'roboto', sans-serif;
}

/* header  */
.header {
    height: 77px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    padding: 12px 7.5%;

}

.header .logo {
    width: 153px;
    height: 43px;
    left: 112px;
    top: 17px;
    background: url('./izifin_logo.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    padding: 5px;
}

/* navigation */
.header .nav {
    display: flex;
    justify-content: space-between;
    width: 400px;
    padding: 12px;
}

.header .nav .nav_item {
    font-family: 'roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height, or 175% */
    text-transform: uppercase;
    color: #25233A;
    cursor: pointer;
}

/* end of header */
/* buttons */
.top_hero_buttons {
    display: flex;
}

.btn {
    border-radius: 10px;
    height: 54px;
    line-height: 54px;
    text-align: center;
    padding: 0 20px;
    font-family: 'roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    /* identical to box height, or 175% */
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

.btn:hover {
    background: #9b4d00;
    color: #fff;
}


.top_btn {
    width: 199px;
    background: #0C780C;
}

.btn_solid {
    width: 199px;
    background: #0C780C;
}

.btn_dumb {
    background: #dfdfdf;
    width: 199px;
}

.nav_item.login_btn {
    background: #0C780C;
    color: #ffffff !important;
    cursor: pointer;
}

/* end of buttons */
/* hero */
.hero {
    width: 100%;
    height: auto;
    min-height: 672px;
    padding: 138px 7%;
    background: rgb(9, 2, 26);
    background: linear-gradient(90deg,
            rgba(9, 2, 26, 1) 0%,
            rgba(9, 2, 16, 1) 50%,
            rgba(9, 2, 16, 0.97) 75%,
            rgba(9, 2, 16, 0.75) 85%,
            rgba(9, 2, 26, 0) 100%), url('./hero.png');
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: right;

}

.hero2 {
    width: 100%;
    height: 365px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./izimerchant.png');
    padding: 102px 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;


}

.hero2.merchant_bg {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./izimerchant.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.hero2.bnpl_bg {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./izibnpl.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.hero2.kyc_bg {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./izibnpl.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.row {}

.hero_inner {}

.hero .hero_title {
    max-width: 522px;
    width: 100%;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 52px;
    color: #FFFFFF;
}

.hero .hero_body {
    font-family: 'roboto', sans-serif;
    max-width: 522px;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
    margin-top: 27px;
}

.hero_btn {
    width: 242px;
    height: 64px;
    background: #C76400;
    border-radius: 10px;
    line-height: 64px;
    margin: 48px 20px 20px 0;
    font-family: 'roboto', sans-serif;
}

.solid {}

.hero_btn.light {
    width: 242px;
    height: 64px;
    border: solid 2px #C76400;
    color: #C76400;
    border-radius: 10px;
    line-height: 64px;
    background: none;
    font-family: 'roboto', sans-serif;
}

.hero2 .hero_title {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 42px;
    text-align: center;
    color: #FFFFFF;
    width: 90%;
    max-width: 484px;
    margin: auto;

}

.hero2 .hero_text {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    width: 90%;
    max-width: 484px;
    color: #FFFFFF;
    margin: auto;
}

.section_light {
    min-height: 672px;
    padding: 138px 7.5%;
}

.section_title {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height, or 175% */
    text-transform: uppercase;
    color: #C76400;
    margin-bottom: 10px;

}

.section_padding {
    padding: 108px 7.5%;
}

.text_left {}

.section_sub_title {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 42px;
    color: #020202;
    max-width: 540px;

}

.section_body {
    font-family: 'roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    /* or 156% */
    color: #25233A;
    margin-top: 24px;
    max-width: 540px;
}

.who_are_we_bg {
    background: url('./whoarewe.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    min-height: 400px;

}

.section_dark {
    min-height: 672px;
    height: auto;
    padding: 138px 7.5%;
    width: 100%;
    height: auto;
    background: #1F1F28;
    border-radius: 100px 100px 0px 0px;
}

.section_dark .section_body,
.section_dark .section_sub_title {
    color: #fff;
}

.section_body {}

.text_center {
    text-align: center !important;
    margin-left: auto !important;
    margin-right: auto;
}

.section_list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 58px auto;
    max-width: 1200px;
}

.section_list .list_item {
    min-height: 556px;
    height: auto;
    background: #30303D;
    border-radius: 30px 30px 0 0;
    position: relative;
    padding: 50px 60px 400px;
    margin-bottom: 20px;
    padding: 16px;
}

.section_list .list_box {
    padding: 16px;
}

.section_list .list_item .image {
    width: 330px;
    max-width: 100%;
    height: 347px !important;
    margin: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 30px 30px 0px 0px;
}

.section_list2 .list_item .image.facematching {
    background: url('./facematching.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.section_list2 .list_item .image.bnpl {
    background: url('./bnpl.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.section_list2 .list_item .image.bnpl_decision {
    background: url('./bnpl_decision.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.section_list2 .list_item .image.bnpl_finance {
    background: url('./bnpl_finance.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.section_list2 .list_item .image.cashier {
    background: url('./cashier_image.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.section_list2 .list_item .image.customer_wallet {
    background: url('./customer_wallet.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.section_list2 .list_item .image.inventory {
    background: url('./inventory.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.section_list .section_list_title {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    padding-top: 40px;
    /* identical to box height, or 130% */

    text-align: center;
    text-transform: uppercase;

    color: #FFFFFF;
}

.section_list .section_list_sub {
    font-family: 'roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #ffffff;
    text-align: center;
    margin-top: 10px;
}

.section_list2 {
    justify-content: center;
    min-height: 403px;
}

.section_list2 .list_item {
    background: #F9F9FC;
    margin-bottom: 20px;
    border-radius: 20px;
    padding: 16px;
    min-height: 400px;
    height: auto;
    ;
}

.section_list2 .list_item .image {
    width: 100%;
    height: 204px;
    background: #D9D9D9;
    border-radius: 20px 20px 0px 0px;
    margin-bottom: 17px;
}

.section_list2 .section_list_title {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height, or 162% */
    text-transform: uppercase;
    color: #C76400;
    margin-bottom: 5px;

}

.section_list2 .section_list_sub {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #25233A;
}

.section_list .learn_more {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height, or 175% */
    text-align: center;
    text-transform: uppercase;
    color: #C76400;
    margin-top: 24px;
}

.learn_more a {
    color: #C76400;
    text-decoration: none;
}

.logo_carousel .title {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height */
    color: #fff;
    text-align: center;


}


.izi_kyc_bg,
.izi_kyc_bg_large {
    background: url('./izikyc.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.izi_bnpl_bg,
.izi_bnpl_bg_large {
    background: url('./izibnpl.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.izi_merchant_bg,
.izi_merchant_bg_large {
    background: url('./izimerchant.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}



.logos {}


.logo_carousel .logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
}

.logo_carousel .logos .logo {
    width: 130px;
    height: 60px;
    margin-top: 30px;
}

.accelerex {
    background: url('./company_logos/accelerex.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.seamlesshr {
    background: url('./company_logos/seamlessHR.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.vfd_group {
    background: url('./company_logos/vfggroup.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.rex_credit {
    background: url('./company_logos/rexcredit.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.sparkle {
    background: url('./company_logos/sparkle_.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.growsari {
    background: url('./company_logos/growsari.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.sterling {
    background: url('./company_logos/sterling.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 25px center 0px;
}

.ajobox {
    background: url('./company_logos/ajobox.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.action_btn {
    background: url('./company_logos/accelerex.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    font-family: 'roboto', sans-serif;
}

.section_big_img {
    width: 90%;
    max-width: 911px;
    margin: auto;
    height: 469px;
    border-radius: 40px 40px 0px 0px;
    margin-top: 60px;
}

.no_bottom_padding {
    padding-bottom: 0 !important;
}

.box_border {
    border-radius: 0 !important;
}

.pricing_bg {
    background: url('./pricing.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

}

.footer {
    padding: 48px 7.5% 0;
    height: auto !important;
    min-height: 300px;
    font-family: 'roboto', sans-serif;
}

.footer_top {
    border-bottom: 1px solid #FFFFFF33;
    display: flex;
    padding-bottom: 39px;
    justify-content: space-between;
}


.social_media_icons {
    display: flex;
}

.social_media_icons .icon {
    background: var(--white);
    width: 25px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    margin-right: 17px;

}

.social_media_icons .icon.twitter {
    background: url('./icons/u_twitter.svg');
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
}

.social_media_icons .icon.facebook {
    background: url('./icons/u_facebook.svg');
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
}

.social_media_icons .icon.linkedIn {
    background: url('./icons/u_linkedin.svg');
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
}

.social_media_icons .icon.instagram {
    background: url('./icons/u_instagram-alt.svg');
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
}

.social_media_icons .icon.youtube {
    background: url('./icons/u_youtube.svg');
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
}

.btn_solid {
    width: 242px;
    height: 64px;
    background: #C76400;
    border-radius: 10px;
    line-height: 64px;
    font-family: 'roboto', sans-serif;
}

.btn_solid:hover {
    background: #9b4d00;
}

.footer_bottom {
    padding-top: 50px;
    padding-bottom: 89px;
    border-bottom: 1px solid #FFFFFF33;
    color: #fff !important;
    font-family: 'roboto';
}

.footer_bottom .izifin_logo {
    width: 168px;
    height: 49px;
    background: url('./izifin_logo_white.png');
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: left;
}

.footer_bottom .logo_text {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #fff !important;
    margin-top: 20px;
}

.footer_list {
    line-height: 24px;
    font-family: 'roboto';
}

.footer_list div {
    margin-top: 10px;
}

.list {}

.footer_list .list_title {
    text-transform: uppercase;

    font-family: 'roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
    /* identical to box height, or 214% */

    text-transform: uppercase;

    color: #FFFFFF;

    opacity: 0.6
}

.list_body {
    font-family: 'roboto', sans-serif;
    font-size: 14px;
}

.contacts {}

.Careers {}

.icon_lists {}

.linkedIn {}

.icon {}

.facebook {}

.instagram {}

.twitter {}

.youtube {}



.footer_list .fieldset {
    background: rgba(255, 255, 255, 0.1);
    border: 0.4px solid #FFFFFF;
    border-radius: 4px;
    padding: 0 14px;
    display: flex;
    height: 40px;

}

.footer_list input {
    background: none;
    border: none;
    outline: none;
    height: 40px;
    line-height: 40px;
    color: #efefef;
    width: calc(100% - 50px);
}

.footer_list .send_btn {
    font-family: 'roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    /* identical to box height, or 200% */
    text-align: center;
    text-transform: uppercase;
    color: #C76400;
    cursor: pointer;
    padding: 0;
    line-height: 40px;
    margin: 0;
    width: 50px;
    cursor: pointer;
}

.footer_list .dumb_send_btn {
    color: #53536c
}

.footer_list .send_btn:hover {
    background: unset;
}

.footer_notes {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: #1F1F28;
    padding: 50px 7.5%;
}

.copyright {
    color: #fff;
}

.footer_notes_right {
    display: flex;
    min-width: 250PX;
    justify-content: space-between;
}

.footer_notes_content {
    color: #fff;
}

.no_padding {
    padding: 0 !important;
}

.show_only_large {
    display: block;
}

.show_only_small {
    display: none;
}

.anchor {
    display: none;
}


/* Extra large devices (large laptops and desktops, 1200px and down) */
@media only screen and (max-width: 1200px) {}

/* Large devices (laptops/desktops, 992px and down) */
@media only screen and (max-width: 992px) {
    .footer_top {}

    .header {
        position: relative;
        width: 100%;
        height: 59px;
        padding: 15px;
    }

    .header .logo {
        width: 91px;
        height: 25px;
        background: url('./izifin_logo.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    .anchor {
        width: 24px;
        height: 24px;
        background: url('./fi_menu.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        right: 22px;
        cursor: pointer;
        margin-top: 3px;
        display: block;
        position: absolute;
    }

    .nav {
        position: absolute;
        display: block;
        height: auto;
        width: 100% !important;
        max-width: 450px;
        background: 100%;
        top: 45px;
        right: 0;
    }

    .nav_item {
        width: 100%;
        background: #FFFFFF;
        padding: 10px 20px !important;
        border-bottom: #30303D33 solid 1px;
    }

    .show_only_small {
        display: block !important;
    }

    .show_only_large {
        display: none !important;
    }

    .section_list .list_item {
        border-radius: 23.1714px;
        padding: 16px;
    }

    .hero {
        padding: 92px 7%;
        min-height: 511px;
    }

    .hero .hero_title {
        font-family: 'IBM Plex Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 46px;
        color: #FFFFFF;
    }

    .hero .hero_boby {
        font-family: 'roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 28px;
        color: #FFFFFF;
    }

    .hero .hero_btn,
    .btn_solid {
        height: 40.73px;
        left: 25px;
        top: 417px;
        border-radius: 6.36364px;
        font-family: 'roboto', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 10.1818px;
        line-height: 38px;
        text-align: center;
        text-transform: uppercase;
    }

    .section_dark,
    .section_light {
        border-radius: 50px 50px 0px 0px;
        padding: 90px 7.5%;
    }

    .section_list .section_list_sub {
        width: 85%;
        margin: 5.5px auto
    }

    .section_list .list_item .image {
        width: 85%;
    }

    .section_list .learn_more {
        margin-top: 18px;
    }

    .logo_carousel .logos .logo {
        width: 70px;
        height: 45px;
        margin-top: 20px;
    }

    .section_sub_title {
        font-family: 'IBM Plex Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        text-transform: capitalize;
        color: #000000;
    }

    .section_body {
        font-family: 'roboto', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        color: #000000;
    }

    .pricing_bg {
        height: 285px;
    }

    .footer_top {
        border-bottom: 1px solid #FFFFFF33;
        display: block;
        text-align: left;
    }

    .footer_top .section_sub_title {
        text-align: left;
    }

    .footer_top .section_body {
        text-align: left;
        margin-bottom: 27px;
    }

    .footer_top .btn_solid {
        max-width: 154px;
    }

    .footer_notes {
        display: block;
    }

    .copyright {
        width: 100%;
        margin-bottom: 29px;
    }

    .get_started_btn {
        width: 160px;
    }
}

.consent-banner {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 40px);
    max-width: 1200px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 1rem 1.5rem;
    z-index: 1000;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
  
.consent-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
  
.consent-banner .icon {
    margin-right: 20px;
}
  
.consent-banner .icon img {
    display: none;
    width: 108px;
}
  
.consent-banner .text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
  
.consent-banner .text h2 {
    font-size: 1.875rem;
    font-weight: 700;
    margin: 0;
}
  
.consent-banner .text p {
    font-size: 1.25rem;
    font-weight: 400;
    margin: 0;
}
  
.consent-banner .actions {
    display: flex;
    gap: 1rem;
}
  
.consent-banner .accept {
    background-color: #FF7F00;
    color: #fff;
    border: none;
    border-radius: 52px;
    padding: 12px 36px;
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;
}
  
.consent-banner .decline {
    background-color: "#ffffff";
    color: #000;
    border: 2px solid #000000;
    border-radius: 52px;
    padding: 12px 32px;
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;
}

@media (min-width: 768px) {
    .consent-banner {
        border-radius: 60px;
        padding: 2.5rem 5rem;
    }
    .consent-banner .icon img{
        display: block;
    }
}


/* Medium devices (landscape tablets, 768px and down) */
@media only screen and (max-width: 768px) {}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}